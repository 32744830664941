
import React from "react"

import Image from "../../Image"

export default ({size}) => (
    <Image 
        id="img-products_lit-halo-channel-letters-shapes"
        alt="Face and Halo Lit EZLit Mounting System"
        backgroundColor={"#efefef"}
        backgroundPreview={null}
        aspectRatio={48}  
        width={600} 
        height={289.51878707976266} 
        sizes={[600, 600]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/products/lit-halo-channel-letters-shapes-600.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/products/lit-halo-channel-letters-shapes-600.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/products/lit-halo-channel-letters-shapes-600.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/products/lit-halo-channel-letters-shapes-600.jpg?v25"
        ]}
    />   
)
