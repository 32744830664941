import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { colors } from "../../settings"

const Button = styled.button`
    background-color: transparent;
    border: 1px solid ${colors.blue};
    color: ${colors.blue};
    padding: 7px 20px;
    font-size: 12px;
    border-radius: 5px;

    display: ${props => props.center?'block':'inline-block'};
    margin-left: ${props => props.center?'auto':'0px'};
    margin-right: ${props => props.center?'auto':'0px'};

    &:hover {
        background-color: ${colors.blue};
        color: white;
    }
`

function SeconddaryButton(props) {
    return (
        props.to ? 
            <Link to={props.to} className="button">
                <Button {...props}>
                    {props.label}
                </Button>
            </Link>
            :
            <Button {...props}>
                {props.label}
            </Button>
    );
}

export default SeconddaryButton