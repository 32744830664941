import React from "react"
import { Link } from "gatsby"
import styled from 'styled-components'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'

import Container from "./Container"
import Button from "../buttons/Button"
import GoogleImage from "../GoogleImage"

import { devices, storage } from "../../settings"

const Hero = styled.div`
  position: relative;
  background-image: url(${storage.url}${props => props.heroSrc});
  background-repeat: no-repeat;
  background-position: top center;
  height: 500px;

  @media ${devices.tablet} {
    background-size: 1024px 321px;
    height: 321px;
  }

  @media ${devices.mobile} {
    background-image: url(${storage.url}${props => props.heroMobileSrc});
    background-size: cover;
    height: 325px;
  }  
`
const Bar = styled.div`
  margin: 0px 0px 30px 0px;
  color: white;
  text-align: right;
  
  @media ${devices.mobile} {
    display: none;
  }  

  h3 {
    font-size: 25px;
    padding: 0px;
    font-weight: 400;
    margin: 0px;

    @media ${devices.tablet} {
      font-size: 20px;
    }
  }

  > div {
    background-color: #8978e0;
    border-radius: 5px;
    padding: 8px;
    position: relative;
    box-shadow: 0px 10px 10px #ccc;

    @media ${devices.laptop} {
      border-radius: 0px;
    }  

    > div {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      a {
        margin-left: 20px;
        button {
          padding: 15px;
          font-size: 16px;
          white-space: nowrap;
          font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        }
      }
    }
  }

  img.person {
    position: absolute;
    bottom: 0px;
    left: -43px;
    height: 260px;

    @media ${devices.tablet} {
      height: 185px;
      left: -35px;
    }
  
    @media ${devices.mobile} {
      display: none;
      left: 0px;
    }  
  }
`

const HeroHeader = (props) => {

  return (
    <>
      <Hero heroSrc={props.heroSrc} heroMobileSrc={props.heroMobileSrc}>
      </Hero>
      <Bar>
        <Container>
          <GoogleImage 
            root
            required
            lazy
            src={props.personSrc} 
            className="person"
            width={438}
            height={260}
          />

          <div>
            <h3>Design, Price and Buy Your Sign Online</h3>
            <Link to="/builder/?from=HomePageHero">
                <Button 
                  label="Start Now"
                  icon={faChevronRight}
                  color="red"
                />
            </Link>
          </div>
        </Container>
      </Bar>    
     </>
  )
}

export default HeroHeader
