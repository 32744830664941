import React from "react"
import styled from 'styled-components'
import { Link } from "gatsby"

import TrustPilot from "../TrustPilot"
import ShipDate from "../ShipDate"

import { colors, devices, storage } from "../../settings"

const WhyPrompts = styled.div`
  display: flex;
  padding-bottom: 20px;
  margin-bottom: 20px;

  @media ${devices.mobile} {
    flex-flow: column;
    margin-left: 15px;
  }

  > * {
    padding: 15px;
    flex-basis: 25%;
    color: black;

    > a {
      color: black;
    }
    &.trustpilot {
      display: flex;
      position: relative;
      align-items: center;
      padding: 0px 10px;
      transform: scale(.75);
      
      @media ${devices.mobile} {
        background-color: #eee;
        transform: none;
        padding: 15px;
      }
    }
    &.trustpilot1 {
      @media ${devices.mobile} {
        display: none !important;
      }
    }
    &.trustpilot2 {
      display: none;

      @media ${devices.mobile} {
       display: block;
       margin-left: -50px;
       padding-left: 0px;
      }
    }
  }

  div.learn {
    color: ${colors.blue};
  }
  h4 {
    color: ${colors.orange};
    margin: 0px;
    font-size: 16px;
    font-weight: 500;
  }
  h2 {
    margin: 0px;
    font-size: 25px;
    font-weight: 400;
  }
`

function WhyWidgets(props) {
    return (
        <WhyPrompts>
          <div className="trustpilot trustpilot1">
            <TrustPilot height={125} type="mini" />
          </div>
          <div>
            <Link to="/support/shipping-policy/">
              <h4>Free Shipping *</h4>
            </Link>
            <Link to="/why/fast-production/">
              <h2>5 Day Production *</h2>
              <div>Should ship by <ShipDate /></div>
              <div className="learn">
                Learn More
              </div>
            </Link>
          </div>
          <Link to="/why/easy-installation/">
            <h4>5 Star Customer Support</h4>
            <h2>Easy Sign Install</h2>
            <div>Do it Yourself and Save $</div>
            <div className="learn">
              Learn More
            </div>  
          </Link> 
          <Link to="/why/pricing/">
            <h4>Quality Made in the USA</h4>
            <h2>Buy Factory Direct</h2>
            <div>100% complete and ready to install</div>
            <div className="learn">
              Learn More
            </div>
          </Link> 
          <div className="trustpilot trustpilot2">
            <TrustPilot height={125} type="mini" />
          </div>
        </WhyPrompts>
    );
}

export default WhyWidgets