import React, { useState } from "react"
import styled from "styled-components"
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import HoverButton from "./HoverButton"
import { parseOutVideoId } from "../../utilities/utils"
import { devices } from "../../settings"

const VideoBackdrop = styled.div`
  position: fixed;
  z-index: 99999;
  left: 0px;
  top: 0px;
  right: 0px;
  bottom: 0px;
  background-color: rgba(0,0,0,.7);
`
const VideoHeader = styled.div`
  text-align: right;
  padding: 15px;
  color: white;
`
const VideoContainer = styled.div`
  margin: 25px auto 25px auto;
 
  iframe { 
    width: 854px;
    height: 480px;
    margin: 0px auto;
    display: block;
    
    @media ${devices.tablet} {
      width: 640px;
      height: 360px;
    }

    @media ${devices.mobile} {
      width: 383px;
      height: 216px;
    }
  }
`

function VideoButton(props) {
  const [open, setOpen] = useState(false);
  const videoUrl = "https://www.youtube.com/embed/" + parseOutVideoId(props.video_url) + "?autoplay=1";

  return (
    <>
      <HoverButton 
        {...props}
        onClick={() => setOpen(true)}
      />

      {open &&
        <VideoBackdrop onClick={() => setOpen(false)}>
          <VideoHeader>
            <FontAwesomeSvgIcon icon={faTimes} size="3x" />
          </VideoHeader>
          <VideoContainer>
            <iframe src={videoUrl} width="420" height="315" title={props.title} frameBorder='0' allowFullScreen></iframe>
          </VideoContainer>
        </VideoBackdrop>
      }
    </>
  );
}

export default VideoButton