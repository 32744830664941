import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import ProductBox from "./ProductBox"
import GoogleImage from "./GoogleImage"
import SeconddaryButton from "./buttons/SecondaryButton"
import { devices } from "../settings"

import CustomPriceRequestImage from "./images/submit-price-request"

const Grid = styled.div`
    display: flex;

    @media ${devices.mobile} {
        display: block;
    }

    > div {
        padding: 10px;

        &:first-child {
            width: 50%;
        }

        @media ${devices.mobile} {
            padding-top: 0px;

            &:first-child {
                width: 80%;
                margin: 0px auto;
            }
        }
    }
`

const CustomPriceProductBox = (props) => {
  return (
    <ProductBox>
        <Link to="/sign-price-request/?from=CustomPriceBox">
            <h2>Submit Your Design for Price</h2>
            <Grid>
                <div>
                    <CustomPriceRequestImage />
                </div>
                <div>
                    <p>
                        We can match your font, artwork or logo with 
                        the same easy installation and quality as our 
                        standard type styles and shapes.  All within
                        24 hours.
                    </p>
                    <p>
                        <SeconddaryButton label="Submit Design for Price" />
                    </p>
                </div>
            </Grid>
        </Link>
    </ProductBox>
  )
}

export default CustomPriceProductBox
