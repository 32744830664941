
import React from "react"

import Image from "../Image"

export default ({size}) => (
    <Image 
        id="img-submit-price-request"
        alt="Custom Price Request"
        backgroundColor={"#efefef"}
        backgroundPreview={null}
        aspectRatio={48}  
        width={250} 
        height={120.83333333333333} 
        sizes={[300, 250]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/submit-price-request-300.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/submit-price-request-250.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/submit-price-request-300.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/submit-price-request-250.jpg?v25"
        ]}
    />   
)
