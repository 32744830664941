
import React from "react"

import Image from "../../Image"

export default ({size}) => (
    <Image 
        id="img-carousel_face-and-back-lit-letters"
        alt="Lit Letters"
        backgroundColor={"#d0dbdb"}
        backgroundPreview={null}
        aspectRatio={100}  
        width={200} 
        height={200} 
        sizes={[100, 200]}     
        devices={['phone', 'desktop']}     
        webpImages={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/carousel/face-and-back-lit-letters-100.webp?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/carousel/face-and-back-lit-letters-200.webp?v25"
        ]}
        images={[
            "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/carousel/face-and-back-lit-letters-100.jpg?v25", "https://storage.googleapis.com/signmonkey-148101.appspot.com/2022/test/carousel/face-and-back-lit-letters-200.jpg?v25"
        ]}
    />   
)
