import React from "react"
import styled from "styled-components"

import { devices } from "../settings"

const Box = styled.div`
    background-color: white;
    border: 1px solid #eee;
    box-shadow: 0px 6px 10px #ccc;
    margin-top: 0px;
    margin-bottom: 25px;
    padding: 20px;

    @media ${devices.mobile} {
        padding: 10px;
    }  

    h2 {
        margin-top: 0px;
        margin-bottom: 8px;
        font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
    }

    a {
        color: black;
    }
`

const ProductBox = (props) => {
  return <Box {...props}>
            {props.children}
        </Box>
}

export default ProductBox
