import React from "react"
import { Link } from "gatsby"
import styled from "styled-components";
import { isMobile } from 'react-device-detect';

import { devices } from "../settings"

import CabinetSignImage from "./images/carousel/lighted-cabinet-sign"
// import ChannelLetterRacewayImage from "./images/carousel/channel-letter-raceway"
import ChannelLettersImage from "./images/carousel/channel-letters"
import CloudSignImage from "./images/carousel/american-flag-lighted-shape-sign"
import FaceHaloLitLettersImage from "./images/carousel/face-halo-lit-letters"
import HaloLitChannelLettersImage from "./images/carousel/halo-lit-channel-letters"
import LedLitLettersImage from "./images/carousel/led-lit-letters"
// import LitHangingSignImage from "./images/carousel/lit-hanging-sign"
import LitLettersImage from "./images/carousel/face-and-back-lit-letters"
import LitShapeSignImage from "./images/carousel/lit-logo-shape-cloud-sign"
// import NeonLettersImage from "./images/carousel/neon-letters"
// import RacewaySignImage from "./images/carousel/raceway-sign"
import ReverseChannelLettersImage from "./images/carousel/reverse-channel-letters"
// import RoofMountImage from "./images/carousel/roof-mount"

const Carousel = styled.div`
  display: grid;
  grid-template-columns: repeat(9, 11.1%);
  grid-gap: 1px;
  align-items: stretch;

  @media ${devices.mobile} {
    grid-template-columns: repeat(4, 25%);

    a:last-child {
        display: none;
    }
  }

  a { 
    display: block;
  }
  img {
    width: 100%;
    height: auto;
  }
`

export default (props) => {
  return (
      <Carousel>
        {/* <Link to="/products/lit-shapes/" className="grow">
            <LitHangingSignImage />
        </Link>           */}
        <Link to="/products/lit-cabinets/" className="grow">
            <CabinetSignImage />
        </Link>                

        <Link to="/products/face-and-halo-lit-channel-letters-and-logos/" className="grow">
            <LitLettersImage />
        </Link>          
        <Link to="/products/halo-lit-channel-letters-and-logos/" className="grow">
            <ReverseChannelLettersImage />
        </Link>         
        <Link to="/products/lit-shapes/" className="grow">
            <LitShapeSignImage />
        </Link>          
        <Link to="/products/face-lit-channel-letters-and-logos/" className="grow">
            <ChannelLettersImage />
        </Link>   
        <Link to="/products/face-and-halo-lit-channel-letters-and-logos/" className="grow">
            <FaceHaloLitLettersImage />
        </Link>          
        <Link to="/products/lit-shapes/" className="grow">
            <CloudSignImage />
        </Link>          
        {/* <Link to="/products/face-lit-channel-letters-and-logos-on-raceway/" className="grow">
            <ChannelLetterRacewayImage />
        </Link>           */}
        <Link to="/products/halo-lit-channel-letters-and-logos/" className="grow">
            <HaloLitChannelLettersImage />
        </Link>          
        <Link to="/products/face-lit-channel-letters-and-logos/" className="grow">
            <LedLitLettersImage />
        </Link>       
        {/* <Link to="/products/face-lit-channel-letters-and-logos-on-raceway/" className="grow">
            <RacewaySignImage />
        </Link>          
        <Link to="/products/face-lit-channel-letters-and-logos/"v>
            <NeonLettersImage />
        </Link>          
        <Link to="/products/accessories/roof-mount-brackets/" className="grow">
            <RoofMountImage />
        </Link>           */}
      </Carousel>      
  )
}