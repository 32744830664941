import React from "react"
import styled from 'styled-components'
import { Link, useStaticQuery, graphql } from "gatsby"
import { faChevronRight } from "@fortawesome/free-solid-svg-icons"

import HoverButton from "../buttons/HoverButton"
import VideoButton from "../buttons/VideoButton"
import CustomPricePromptBox from "../CustomPriceProductBox"
import Container from "../layout/Container"
import GoogleImage from "../GoogleImage"

import { devices, storage } from "../../settings"

import HaloImage from "../images/products/halo-channel-letters-shapes"
import CabinetDoubleImage  from "../images/products/lit-cabinet-retainer-double"
import CabinetSingleImage from "../images/products/lit-cabinet-retainer-single"
import ChannelLettersRacewayImage from "../images/products/lit-channel-letters-shapes-raceway"
import ChannelLettersImage from "../images/products/lit-channel-letters-shapes"
import HaloLitImage from "../images/products/lit-halo-channel-letters-shapes"
import LitShapeImage from "../images/products/lit-shape-sign"

const ContentHeader = styled.div`
  font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
  text-align: center;
  margin: 20px;

  h2 {
      margin: 0px;
      font-size: 35px;
      font-weight: 700;
      color: #444;
      line-height: 1.1em;
  }
  div {
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-size: 18px;
    font-weight: 300;
    margin: 10px 0px 0px 0px;
  }
`
const Content = styled.div`
  border-top: 3px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
  background-image: url(${storage.url}2020/tiles/grey3.jpg);
  margin-top: 100px;
  margin-bottom: 30px;
  padding-top: 0px;
  padding-bottom: 10px;

  > div {
    > div:first-child {
      margin-top: -50px;
    }
  }
  @media ${devices.tablet} {
    > div {
      padding: 10px;

      > div:first-child {
        margin-top: -75px;
      }
    }
  }    
  @media ${devices.mobile} {
    margin-bottom: 0px;
  }  
`
const VideoPlaceholder = styled.div`
  background-color: white;
  border-radius: 5px;
  border: 1px solid #eee;
  box-shadow: 0px 6px 10px #ccc;
  padding: 20px;
  margin-top: -250px;
  height: 500px;

  @media ${devices.mobile} {
    margin-top: -125px;
    height: 250px;
  }
`
const ProductBox = styled.div`
  background-color: white;
  border-radius: 5px;
  border: 1px solid #eee;
  box-shadow: 0px 6px 10px #ccc;
  margin-top: 35px;
  padding: 20px 10px;

  display: flex;

  @media ${devices.mobile} {
    display: block;
  }

  > a {
    width: 300px;
  }
  > div {
    padding-left: 20px;
    flex: 1;

    @media ${devices.mobile} {
      padding-left: 0px;
    }

    h3 {
      font-family: "Roboto Slab", "Helvetica", "Arial", sans-serif;
      font-size: 18px;
      border-bottom: 1px solid #eee;
      padding-bottom: 10px;
      margin-top: 5px;
      margin-bottom: 6px;
    }
    p {
      font-size: 100%;
    }
    > div {
      margin-top: 30px;

      button[color=darkgrey] {
        border-color: rgb(200,200,200);
        color: rgb(125,125,125);
      }
    }
    
  }
`

function ProductsWidget(props) {
    const data = useStaticQuery(graphql`
        {
            products: allMysqlProducts {
                edges {
                    product: node {
                        id
                        name
                        description
                    }
                }
            }
        }
    `);    
    const products = data.products.edges;

    return (
        <>
            <ContentHeader>
                <h2>Find the Right Sign for Your Project</h2>
                <div>
                All signs feature our exclusive EZ installation and ship 100% complete and ready to install, no assembly necessary
                </div>
            </ContentHeader>

            <Content>
                <Container width={800}>
                {false && 
                    <VideoPlaceholder>
                    [VIDEO PLACEHOLDER]
                    </VideoPlaceholder>
                }
                <ProductBox>
                    <Link to="/products/face-lit-channel-letters-and-logos/">
                        <ChannelLettersImage />
                    </Link>
                    <div>
                        <h3>Channel Letters with EZLit Plug-n-Play Install</h3>
                        <p>
                        {products.find(p => p.product.name == "lit-channel-letters-shapes").product.description}
                        </p>
                        <div>
                        <Link to="/products/face-lit-channel-letters-and-logos/">
                            <HoverButton
                            label = "Learn More"
                            color = "blue"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <Link to="/builder/?from=HomePageProducts&product=lit-channel-letters-shapes">
                            <HoverButton
                            label = "Design &amp; Price"
                            icon = {faChevronRight}
                            color = "red"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <VideoButton
                            label = "Watch Video"
                            color = "darkgrey"
                            size = "small"
                            video_url = "https://www.youtube.com/watch?v=ycyOna1l1Tc&rel=0"
                        />
                        </div>
                    </div>
                </ProductBox>
                <ProductBox>
                    <Link to="/products/face-lit-channel-letters-and-logos-on-raceway/">
                        <ChannelLettersRacewayImage />
                    </Link>
                    <div>
                        <h3>Raceway Mounted Channel Letter Signs</h3>
                        <p>                
                        {products.find(p => p.product.name == "lit-channel-letters-shapes-raceway").product.description}
                        </p>
                        <div>
                        <Link to="/products/face-lit-channel-letters-and-logos-on-raceway/">
                            <HoverButton
                            label = "Learn More"
                            color = "blue"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <Link to="/builder/?from=HomePageProducts&product=lit-channel-letters-shapes-raceway">
                            <HoverButton
                            label = "Design &amp; Price"
                            icon = {faChevronRight}
                            color = "red"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <VideoButton
                            label = "Watch Video"
                            color = "darkgrey"
                            size = "small"
                            video_url = "https://www.youtube.com/watch?v=-ufAxbgqB8Y&rel=0"
                        />
                        </div>
                    </div>
                </ProductBox>          
                <ProductBox>
                    <Link to="/products/lit-shape-sign/">
                        <LitShapeImage />
                    </Link>
                    <div>
                        <h3>Self Contained Lit Shape Signs</h3>
                        <p>
                        {products.find(p => p.product.name == "lit-shape-sign").product.description}
                        </p>
                        <div>
                        <Link to="/products/lit-shape-sign/">
                            <HoverButton
                            label = "Learn More"
                            color = "blue"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <Link to="/builder/?from=HomePageProducts&product=lit-shape-sign">
                            <HoverButton
                            label = "Design &amp; Price"
                            icon = {faChevronRight}
                            color = "red"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <VideoButton
                            label = "Watch Video"
                            color = "darkgrey"
                            size = "small"
                            video_url = "https://www.youtube.com/watch?v=4DduIMJGoQs&rel=0"
                        />
                        </div>
                    </div>
                </ProductBox>
                <ProductBox>
                    <Link to="/products/lit-cabinets/">
                        <CabinetSingleImage />
                    </Link>
                    <div>
                        <h3>Single Face Rectangular Illuminated Cabinet Sign</h3>
                        <p>
                        Lit through the faces with High Output LEDs. Face of the sign is held with a metal retainer which makes changing 
                        the sign face easy. Strong One piece construction, for easy installation.
                        </p>
                        <div>
                        <Link to="/products/lit-cabinets/">
                            <HoverButton
                            label = "Learn More"
                            color = "blue"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <Link to="/builder/?from=HomePageProducts&product=lit-cabinet-retainer-single">
                            <HoverButton
                            label = "Design &amp; Price"
                            icon = {faChevronRight}
                            color = "red"
                            size = "small"
                            />
                        </Link>
                        </div>
                    </div>
                </ProductBox>
                <ProductBox>
                    <Link to="/products/lit-cabinets/">
                        <CabinetDoubleImage />
                    </Link>
                    <div>
                        <h3>Double Face Rectangular Illuminated Cabinet Sign</h3>
                        <p>
                        Lit through both faces with double High Output LEDs. Faces of the sign are held with a metal retainer which makes changing 
                        the sign faces easy. Simple Pole Mount Design for easy installation.
                        </p>
                        <div>
                        <Link to="/products/lit-cabinets/">
                            <HoverButton
                            label = "Learn More"
                            color = "blue"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <Link to="/builder/?from=HomePageProducts&product=lit-cabinet-retainer-double">
                            <HoverButton
                            label = "Design &amp; Price"
                            icon = {faChevronRight}
                            color = "red"
                            size = "small"
                            />
                        </Link>
                        </div>
                    </div>
                </ProductBox>
                <ProductBox>
                    <Link to="/products/face-and-halo-lit-channel-letters-and-logos/">
                        <HaloLitImage />
                    </Link>
                    <div>
                        <h3>Face &amp; Halo Lit Letters and Logos</h3>
                        <p>
                        {products.find(p => p.product.name == "lit-halo-channel-letters-shapes").product.description}
                        </p>
                        <div>
                        <Link to="/products/face-and-halo-lit-channel-letters-and-logos/">
                            <HoverButton
                            label = "Learn More"
                            color = "blue"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <Link to="/builder/?from=HomePageProducts&product=lit-halo-channel-letters-shapes">
                            <HoverButton
                            label = "Design &amp; Price"
                            icon = {faChevronRight}
                            color = "red"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <VideoButton
                            label = "Watch Video"
                            color = "darkgrey"
                            size = "small"
                            video_url = "https://www.youtube.com/watch?v=p9dU_sS-uvg&rel=0"
                        />
                        </div>
                    </div>
                </ProductBox>           
                <ProductBox>
                    <Link to="/products/halo-lit-channel-letters-and-logos/">
                        <HaloImage />
                    </Link>
                    <div>
                        <h3>Halo Lit Letters and Logos</h3>
                        <p>
                        {products.find(p => p.product.name == "halo-channel-letters-shapes").product.description}
                        </p>
                        <div>
                        <Link to="/products/halo-lit-channel-letters-and-logos/">
                            <HoverButton
                            label = "Learn More"
                            color = "blue"
                            size = "small"
                            />
                        </Link>
                        {' '}
                        <Link to="/builder/?from=HomePageProducts&product=halo-channel-letters-shapes">
                            <HoverButton
                            label = "Design &amp; Price"
                            icon = {faChevronRight}
                            color = "red"
                            size = "small"
                            />
                        </Link>
                        </div>
                    </div>
                </ProductBox>
                <br />
                <br />
                <CustomPricePromptBox />
                </Container>
            </Content>
        </>
    );
}

export default ProductsWidget