import React from "react"

import { shipping } from '../settings'
import { graphql, useStaticQuery } from "gatsby";

function convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", {timeZone: tzString}));   
}
function convertToOurTimezone(date) {
    return convertTZ(date, "America/New_York");
}
// const holidays = shipping.holidays.map(d => {
//     return new Date(Date.UTC(d.getUTCFullYear(), d.getUTCMonth(), d.getUTCDate()));
// })

// function isHoliday(date) {
//     let found = false;
//     let utcDate =  new Date(Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate()));   

//     for (const holiday of holidays) {
//         //console.log(holiday.getTime(), utcDate.getTime());

//         if (holiday.getTime() == utcDate.getTime())
//             found = true;
//     }

//     return found;
// }

function addDays(d, days) {
    var date = new Date(d.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}

function format(date) {
    return date.toLocaleString().split(",")[0];
}

function calcShipDate(date = new Date(), days=shipping.productionDays, holidays) {

    if (!date)
        date = new Date();

    // Make sure we are working in eastern timezone
    date = convertToOurTimezone(date);

    // if (getHours(date) < 12)
    //     days--;

    let day = 1;

    if (date.getHours() >= 12) {
        date = addDays(date, 1);
    }

    // saftey escape
    let counter = 0;
    let maxIterations = 25;

    while (day <= days && counter < maxIterations) {
        const dow = date.getDay();
        const isHoliday = holidays && holidays.includes(format(date));
        
        // Is this a weekend?  If so, then skip 
        if (dow == 0 || dow == 6) {
            date = addDays(date, 1);    
        }
        else if (isHoliday) {
            date = addDays(date, 1);    
        }
        else if (day < days) {
            date = addDays(date, 1);
            day++; // valid production day          
        }

        counter++; // saftey escape
    }

    return date; 
}

function ShipDate(props) {
    const data = useStaticQuery(graphql`
        {
            holidays: allMysqlHolidays {
                edges {
                    node {
                        date
                    }
                }
            }      
        }
    `);

    const holidays = data.holidays.edges.map(e => e.node).map(n => format(new Date(n.date)));

    return (
        <span>{ format(calcShipDate(new Date, props.productionDays || shipping.productionDays, holidays)) }</span>
    );
}

export default ShipDate